
/* -------------------------------------------------------------------------- */
/*                                   Borders                                  */
/* -------------------------------------------------------------------------- */

// Safari clipping mask
.overflow-hidden[class*='rounded']{
  mask-image: radial-gradient($white, $black);
}

@each $key, $utility in $border-color-utilities {
    @include generate-utility($utility, '');
}